import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { FieldValue, serverTimestamp, Timestamp } from "firebase/firestore";
import { createRef, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useDoc } from "../../../hooks/useDoc";
import { useServerTime } from "../../../hooks/useServetTime";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";
import { BrainChaseWrapper } from "../BrainChaseWrapper";
import background from "../image/red_black_bg.png";

export type BrainChaseUser = {
  class: string;
  name: string;
  displayName?: string;
  problem: {
    [key: string]: {
      time: Timestamp | FieldValue;
    };
  };
  currentProblem: number;
  rank?: number;
  lastAnswer?: string;
  lastAnswerAt?: Timestamp | FieldValue;
};

export type UserData = {
  class: string;
  name: string;
};

export const BrainChaseGame: React.FC = () => {
  const { roomId, hash } = useParams<{ roomId: string; hash: string }>();
  const [room] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const [userData, setUserData] = useDoc<BrainChaseUser>({
    path: "brainchase/" + roomId + "/user/" + hash,
  });

  const [answerMessage, setAnswerMessage] = useState<string>("");

  const currentProblem = userData?.currentProblem ?? 0;
  const playerName = userData?.name ?? "";

  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = createRef<HTMLInputElement>();
  const [isComposition, setIsComposition] = useState(false);

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  const offsetTime = parseInt(
    roomSet?.classes[parseInt(userData?.class ?? "0")].time ?? "0"
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === " ") {
      setInputValue("");
    } else {
      setInputValue(value);
    }
  };

  const start = room?.mode === "playing";
  const clearTime = useMemo(() => {
    return userData && room && userData.problem?.[currentProblem - 1].time
      ? ((userData.problem[currentProblem - 1]?.time as Timestamp)
          .toDate()
          .getTime() -
          (room.startTime as Timestamp).toDate().getTime()) /
          1000 -
          defaultOffsetTime
      : 0;
  }, [userData, room, currentProblem]);

  const { serverTime } = useServerTime({
    serverTimeDocId: playerName !== "" ? playerName ?? "player" : "player",
    runTimer: start && playerName !== "",
    timerInterval: 100,
  });

  useEffect(() => {
    if (answerMessage !== "") {
      setTimeout(() => {
        setAnswerMessage("");
      }, 2000);
    }
  }, [answerMessage]);

  const submit = () => {
    if (inputValue === "") return;
    if (!userData) {
      return;
    }
    if (
      !roomSet?.problems[currentProblem].answer.split(",").includes(inputValue)
    ) {
      setAnswerMessage("不正解です！");
      setInputValue("");
      setUserData({
        ...userData,
        lastAnswer: inputValue,
        lastAnswerAt: serverTimestamp(),
      });
      return;
    }
    setAnswerMessage("正解です！");
    setInputValue("");
    setUserData({
      ...userData,
      class: userData?.class ?? "0",
      problem: {
        ...userData?.problem,
        [currentProblem.toString()]: {
          time: serverTimestamp(),
        },
      },
      currentProblem: currentProblem + 1,
      lastAnswer: inputValue,
      lastAnswerAt: serverTimestamp(),
    });
    inputRef.current?.focus();
  };

  const countDown =
    !room || !serverTime || !room.startTime
      ? defaultOffsetTime
      : offsetTime +
        defaultOffsetTime -
        (serverTime?.getTime() -
          (room.startTime as Timestamp)?.toDate()?.getTime()) /
          1000;
  const remainingTime =
    !room || !serverTime || !room.startTime
      ? 600
      : Math.max(
          ((room.startTime as Timestamp).toDate().getTime() +
            (room.timeLimit ?? 600) * 1000 -
            serverTime?.getTime()) /
            1000 +
            defaultOffsetTime,
          0
        );

  const maxProblem = roomSet?.problems.length ?? 15;

  const lastAnsweredAt = userData?.lastAnswerAt as Timestamp | undefined;

  const thinkingTime = useMemo(() => {
    if (!room || !serverTime) return 0;
    if (currentProblem !== 0) {
      const lastCorrectTime = userData?.problem?.[currentProblem - 1]?.time as
        | Timestamp
        | undefined;
      return (
        (serverTime?.getTime() -
          (lastCorrectTime?.toDate().getTime() ?? serverTime?.getTime())) /
        1000
      );
    } else {
      return (
        (serverTime?.getTime() -
          (room.startTime as Timestamp)?.toDate().getTime()) /
          1000 -
        (offsetTime + defaultOffsetTime)
      );
    }
  }, [
    lastAnsweredAt,
    serverTime,
    room,
    offsetTime,
    defaultOffsetTime,
    currentProblem,
    userData?.problem,
  ]);

  const isMobile = useMediaQuery("(max-width: 500px)");

  if (!roomSet || !room) {
    return <></>;
  }

  return (
    <BrainChaseWrapper>
      <Bg>
        <Box sx={{ minHeight: "100vh" }}>
          {(room.mode === "waiting" ||
            (room.mode === "playing" && countDown > 0)) && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: '"Bebas Neue", sans-serif',
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontSize: "20vw",
                  background: "linear-gradient(#fff,#ccc)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  mb: 8,
                }}
              >
                BRAIN CHASE
              </Typography>

              <Box sx={{ my: 2 }} />
              {roomSet?.config?.title && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    種目名
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                    }}
                  >
                    {roomSet?.config?.title ?? ""}
                  </Typography>
                </>
              )}

              <Box sx={{ my: 2 }} />
              <Typography
                variant="h3"
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                }}
              >
                あなたのクラス
              </Typography>
              {roomSet && (
                <Typography variant="h3" sx={{ mt: 2 }}>
                  {roomSet?.classes[parseInt(userData?.class ?? "0")].class ??
                    ""}
                </Typography>
              )}
              <Box sx={{ my: 2 }} />
              {room.mode === "waiting" && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    スタートを
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    お待ちください
                  </Typography>
                </>
              )}
              {room.mode === "playing" && countDown > 0 && (
                <>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                    }}
                  >
                    スタートまで
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontSize: "6rem",
                    }}
                  >
                    {Math.floor(Math.ceil(countDown) / 60)}:
                    {("" + (Math.ceil(countDown) % 60)).padStart(2, "0")}
                  </Typography>
                </>
              )}
            </Stack>
          )}
          {room.mode === "playing" &&
            countDown <= 0 &&
            currentProblem < maxProblem && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100vw",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                    }}
                  >
                    終了まで {Math.floor(Math.ceil(remainingTime) / 60)}:
                    {("" + (Math.ceil(remainingTime) % 60)).padStart(2, "0")}
                  </Typography>
                  <Box sx={{ height: 10 }} />
                  <div
                    className="question"
                    style={{
                      width: "100%",
                      border: "none",
                      maxWidth: "100%",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={roomSet?.problems[currentProblem].image ?? ""}
                      alt="問題画像"
                      style={{
                        margin: 0,
                        opacity: 0.9,
                        border: "2vw solid #d41b05",
                      }}
                    />
                  </div>
                  {answerMessage === "" ? (
                    <Box sx={{ height: 48 }} />
                  ) : (
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {answerMessage}
                    </Typography>
                  )}
                  {
                    //description
                    roomSet?.problems[currentProblem].description && (
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 1,
                        }}
                      >
                        解答形式:{" "}
                        {roomSet?.problems[currentProblem].description}
                      </Typography>
                    )
                  }
                  <Stack direction={isMobile ? "column" : "row"} spacing={4}>
                    <input
                      type="text"
                      value={inputValue}
                      ref={inputRef}
                      onChange={handleInputChange}
                      className="answer"
                      onCompositionStart={() => setIsComposition(true)}
                      onCompositionEnd={() => setIsComposition(false)}
                      onKeyDown={(e) => {
                        if (isComposition) return;
                        if (e.key === "Enter") {
                          submit();
                        }
                      }}
                      autoFocus
                    />{" "}
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="large"
                        onClick={submit}
                        variant={"contained"}
                      >
                        送信
                      </Button>
                    </div>
                  </Stack>
                  {
                    // hints
                    roomSet?.problems[currentProblem].hints &&
                      (
                        roomSet?.problems[currentProblem].hints as {
                          text: string;
                          time: string;
                        }[]
                      ).map((hint, i) => {
                        return thinkingTime > parseInt(hint.time) ? (
                          <Box sx={{ mx: 2 }}>
                            <Typography
                              key={i}
                              variant="h6"
                              sx={{
                                mt: 2,
                              }}
                            >
                              ヒント{i + 1}: {hint.text}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ mx: 2 }}>
                            <Typography
                              key={i}
                              variant="h6"
                              sx={{
                                mt: 2,
                              }}
                            >
                              ヒント{i + 1}:{" "}
                              {parseInt(hint.time) - Math.floor(thinkingTime)}
                              秒後に公開されます。
                            </Typography>
                          </Box>
                        );
                      })
                  }
                  <Box sx={{ height: 10, margin: "auto" }} />
                </Stack>
              </>
            )}
          {(room.mode === "end" ||
            (room.mode === "playing" && currentProblem === maxProblem)) && (
            <>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                }}
              >
                <Typography variant="h1" sx={{ mt: 2 }}>
                  Result
                </Typography>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  名前:{" "}
                  {userData?.displayName ? (
                    <>
                      <span
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        {userData?.name}
                      </span>
                      → {userData?.displayName}
                    </>
                  ) : (
                    userData?.name
                  )}
                </Typography>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  クラス:{" "}
                  {roomSet?.classes[parseInt(userData?.class ?? "0")].class ??
                    ""}
                </Typography>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  (+{offsetTime}秒)
                </Typography>
                <Box />
                <Typography variant="h3" sx={{ mt: 2 }}>
                  成績:{" "}
                  {currentProblem === maxProblem
                    ? `${(clearTime / 60) | 0}分${(
                        "" +
                        (Math.floor(clearTime) % 60)
                      ).padStart(2, "0")}秒${Math.floor(clearTime * 10) % 10}
                でクリア！`
                    : `Q${currentProblem + 1}で終了`}
                </Typography>
                {userData?.rank && (
                  <>
                    <Typography variant="h3" sx={{ mt: 2 }}>
                      順位: {userData.rank}
                    </Typography>

                    <Button
                      onClick={() => {
                        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                          roomSet.config.tweetText.replace(
                            "@rank@",
                            "" + (userData?.rank ?? "0")
                          )
                        )}`;
                        window.open(url, "_blank");
                      }}
                      variant={"contained"}
                      size={"large"}
                      sx={{ mx: "auto", my: 5 }}
                    >
                      Twitterでシェア
                    </Button>
                  </>
                )}
              </Stack>
            </>
          )}
        </Box>
      </Bg>
    </BrainChaseWrapper>
  );
};

const Bg = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
  font-family: "Noto Sans JP", sans-serif;
`;
